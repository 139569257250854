import React from "react";

const Footer = () => (
  <footer className="bg-light p-3 text-center">
    <div className="logo" />
    <p>
      SaaS Marketplace - Built by developers for developers &hearts; <a href="https://www.plate.sh">Plate.sh</a> &hearts;
    </p>
    
  </footer>
);

export default Footer;
